
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_reports(params)  {
            return await axios.post('reports/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_reports()  {
            // return await axios.get('reports/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_reports')
        },
        async delete_reports(report_id)  {
            return await axios.post('reports/delete.php' , {
                report_id : report_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(report_id)  {
            return await axios.post('reports/readOne.php' , {
                report_id : report_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_reports(params)  {
            return await axios.post('reports/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_reports(params)  {
            return await axios.post('reports/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
